<template>
  <div class="ServiceHome">
    <div class="ServiceHome-info">
      <!-- 主页 -->
      <div class="Service-info" v-if="providerInfo">
        <div class="left-info">
          <div class="cover">
            <el-avatar :size="128" :src="providerInfo.user.avatarImg"></el-avatar>
          </div>
          <div class="Service-data">
            <div class="ServiceName">
              <div class="name">{{ providerInfo.providerName }}</div>
              <div class="follow">345人关注</div>
              <el-tag size="small" effect="plain" type="warning" v-if="providerInfo.providerType===1">个人</el-tag>
              <el-tag size="small" effect="plain" v-if="providerInfo.providerType===2">企业</el-tag>
            </div>
            <div class="Service-trends">
              <div class="reply">
                <div>3分钟内回复率</div>
                <div>61%</div>
                <div>平均回复</div>
              </div>
              <span></span>
              <div class="sales">
                <div>总销量</div>
                <div>170</div>
                <div>准时交付率80%</div>
              </div>
              <span></span>
              <div class="evaluate">
                <div>综合评分</div>
                <div>5.0</div>
                <div>评价55条</div>
              </div>
            </div>
          </div>
        </div>
        <div class="right-info">
          <div>关注</div>
          <div @click.stop="clickShowChat">发消息</div>
        </div>
      </div>
      <!-- 服务商 上架的 商品 -->
      <div class="shop-list">
        <div class="shop-title">商品 ({{ serviceList.length }})</div>
        <div class="shop-lists">
          <!-- list -->
          <el-row v-for="(page, index) of pages" :key="index" type="flex" justify="start" :gutter="20">
            <el-col :span="7" v-for="(item) of page" :key="item.id">
              <list1 :items="item" @GetClick="toDetailsPage"></list1>
            </el-col>
          </el-row>
        </div>
      </div>
      <!-- 服务商 评价 -->
      <!-- <div class="shop-evaluate">
        <div class="shop-title">评价 (6)</div>
        <Evaluate />
      </div>-->
    </div>
  </div>

</template>
<script>
// import Evaluate from "@/components/Evaluate";
import list1 from "@/components/List/components/list1.vue";
import { mapActions } from "vuex";

export default {
  name: 'Provider',
  data() {
    return {
      serviceList: [],
      providerInfo: "",
      providerId: this.id
    };
  },
  props: ['id'],
  components: {
    // Evaluate,
    list1,
  },
  created() {
    if (!this.providerId) this.providerId = this.$route.query.id
    // 如果用携带参数的情况下 需要携带 参数
    this.getProviderInfo(this.providerId);
  },
  computed: {
    pages() {
      const pages = []
      if (!this.serviceList || !this.serviceList.length) return pages
      this.serviceList.forEach((item, index) => {
        const page = Math.floor(index / 3) // 代表3条为一行，随意更改
        if (!pages[page]) {
          pages[page] = []
        }
        pages[page].push(item)
      })
      return pages
    }
  },
  methods: {
    ...mapActions(['showChat']),
    // 获取服务商信息
    getProviderInfo(id) {
      if (!id) return
      let url = "/app-api/hiring/service-provider-info/get/user?userId=";
      this.$axios
          .get(url + id)
          .then((res) => {
            if (res.data.code === 0) {
              this.providerInfo = res.data.data;
              this.getProviderServiceList(id);
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 获取服务商发布的列表
    getProviderServiceList(id) {
      if (!id) return
      let url = "/app-api/hiring/push-service/page";
      this.$axios
          .get(url, {
            params: {
              pageNo: 1,
              pageSize: 100,
              userId: id
            }
          })
          .then((res) => {
            if (res.data.code === 0) {
              this.serviceList = res.data.data.list;
            }
          })
          .catch((err) => {
            return Promise.reject(err);
          });
    },
    // 跳转到 服务大厅 详情
    toDetailsPage(Ids) {
      this.$router.push({ path: "/ServiceHall/Details", query: { id: Ids } });
    },
    clickShowChat() {
      if (!this.$store.getters.getUser || !this.$store.getters.getUser.id) {
        this.$notify.warning('您还未登录，请先登录')
        return
      }
      if (this.providerInfo.userPhone === this.$store.getters.getUser.userPhone) {
        this.$notify.info('您不能向自己在线咨询！')
        return
      }
      this.showChat({
        show: true,
        phone: this.providerInfo.userPhone,
        goods: {
          id: '',
          name: '',
          desc: '',
          img: ''
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.el-col {
  width: unset;
}

.el-row + .el-row {
  margin-top: 20px;
}

.ServiceHome {
  margin: 30px 0;
  padding: 40px;
  background: #ffffff;
  
  .ServiceHome-info {
    box-sizing: border-box;
    
    .shop-title {
      font-weight: 400;
      color: #000000;
      line-height: 23px;
      letter-spacing: 2px;
      font-size: 20px;
      margin-top: 40px;
    }
    
    .shop-list {
      margin-top: 30px;
      
      .shop-lists {
        margin-top: 30px;
        
        .items {
          margin-bottom: 20px;
        }
      }
    }
    
    .Service-info {
      width: 100%;
      height: 229px;
      border-radius: 8px;
      border: 1px solid #cdcdcd;
      padding: 30px;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      
      .left-info {
        display: flex;
        // .cover {
        // }
        .Service-data {
          margin-left: 41px;
          
          .ServiceName {
            display: flex;
            align-items: center;
            margin-right: 30px;
            
            .name {
              font-weight: 400;
              color: #000000;
              font-size: 20px;
            }
            
            .follow {
              font-weight: 400;
              color: #999999;
              font-size: 14px;
              margin-left: 20px;
            }
            
            .el-tag {
              line-height: unset;
              width: 48px;
              height: 20px;
              padding: 0 10px;
              margin-left: 30px;
            }
            
            .el-tag--plain {
              color: #3d8bff;
              border-color: #3d8bff;
            }
            
            .el-tag--warning {
              color: #f46700;
              border-color: #f46700;
            }
            
          }
          
          .Service-trends {
            display: flex;
            align-items: center;
            margin-top: 30px;
            
            .reply,
            .sales,
            .evaluate {
              div {
                text-align: center;
              }
              
              div:nth-of-type(1) {
                font-weight: 400;
                color: #999999;
                line-height: 16px;
                font-size: 14px;
              }
              
              div:nth-of-type(2) {
                font-weight: 400;
                color: #000000;
                line-height: 23px;
                font-size: 20px;
                margin-top: 7px;
                font-weight: 700;
              }
              
              div:nth-of-type(3) {
                font-weight: 400;
                color: #999999;
                line-height: 16px;
                font-size: 14px;
                margin-top: 10px;
              }
            }
            
            span {
              width: 1px;
              height: 69px;
              background: #cdcdcd;
              margin: 0 23px;
            }
          }
        }
      }
      
      .right-info {
        display: flex;
        align-items: center;
        // margin-top: 60px;
        div {
          width: 99px;
          height: 40px;
          box-sizing: border-box;
          border-radius: 4px;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
        }
        
        div:nth-of-type(1) {
          background: #fc3737;
          color: #ffffff;
        }
        
        div:nth-of-type(2) {
          border: 1px solid #cdcdcd;
          margin-left: 30px;
          color: #000000;
        }
      }
    }
  }
}
</style>
